import React, { useEffect, useRef, useState } from "react";
import './App.scss';
import QRCodeStyling from "qr-code-styling";
import SolidColorBox from './component/SolidColorBox'; // Đường dẫn tới file SolidColorBox.js
import GradientColorBox from './component/GradientColorBox'; // Đường dẫn tới file GradientColorBox.js


const qrCode = new QRCodeStyling({});

function App() {

  const [dataUrl, setDataUrl] = useState("https://phong.info");
  const [fileExt, setFileExt] = useState("png");
  const [imageFile, setImageFile] = useState("");
  const [qrWidth, setQrWidth] = useState(1500);
  const [qrHeight, setQrHeight] = useState(1500);
  const [qrMargin, setQrMargin] = useState(70);
  const [disableDownload, setDisableDownload] = useState(false);

  //Dot
  const [qrDotStyle, setQrDotStyle] = useState("square");
  const [radioDotColor, setRadioDotColor] = useState("single");
  const [solidDotColor, setSolidDotColor] = useState("#000000");
  const [gradientDotColor, setGradientDotColor] = useState(null);
  const [gradientDotColorType, setGradientDotColorType] = useState("linear");
  const [gradientDotColorRotation, setGradientDotColorRotation] = useState(0);
  const [gradientDotColorStart, setGradientDotColorStart] = useState("#5614b0");
  const [gradientDotColorEnd, setGradientDotColorEnd] = useState("#fbc82e");

  //Corner square
  const [qrCSStyle, setQrCSStyle] = useState("square");
  const [radioCSColor, setRadioCSColor] = useState("single");
  const [solidCSColor, setSolidCSColor] = useState("#000000");
  const [gradientCSColor, setGradientCSColor] = useState(null);
  const [gradientCSColorType, setGradientCSColorType] = useState("linear");
  const [gradientCSColorRotation, setGradientCSColorRotation] = useState(0);
  const [gradientCSColorStart, setGradientCSColorStart] = useState("#5614b0");
  const [gradientCSColorEnd, setGradientCSColorEnd] = useState("#fbc82e");

  //Corner dot
  const [qrCDStyle, setQrCDStyle] = useState("square");
  const [radioCDColor, setRadioCDColor] = useState("single");
  const [solidCDColor, setSolidCDColor] = useState("#000000");
  const [gradientCDColor, setGradientCDColor] = useState(null);
  const [gradientCDColorType, setGradientCDColorType] = useState("linear");
  const [gradientCDColorRotation, setGradientCDColorRotation] = useState(0);
  const [gradientCDColorStart, setGradientCDColorStart] = useState("#5614b0");
  const [gradientCDColorEnd, setGradientCDColorEnd] = useState("#fbc82e");

  //Background
  const [radioBGColor, setRadioBGColor] = useState("single");
  const [solidBGColor, setSolidBGColor] = useState("#ffffff");
  const [gradientBGColor, setGradientBGColor] = useState(null);
  const [gradientBGColorType, setGradientBGColorType] = useState("linear");
  const [gradientBGColorRotation, setGradientBGColorRotation] = useState(0);
  const [gradientBGColorStart, setGradientBGColorStart] = useState("#5614b0");
  const [gradientBGColorEnd, setGradientBGColorEnd] = useState("#fbc82e");

  //ETC
  const [imageHideBGDot, setImageHideBGDot] = useState(true);
  const [imageMargin, setImageMargin] = useState(20);
  const [imageSize, setImageSize] = useState(0.4);
  const [qrTypeNumber, setqrTypeNumber] = useState(0);
  const [qrMode, setQrMode] = useState("Byte");
  const [qrCorrectLv, setQrCorrectLv] = useState('Q');

  const qrRef = useRef(null);


  useEffect(() => {
    qrCode.append(qrRef.current);

    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = 'Are you sure you want to leave?';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };

  }, []);

  useEffect(() => {
    try {
      qrCode.update({
        data: dataUrl,
        image: imageFile,
        width: qrWidth,
        height: qrHeight,
        margin: qrMargin,

        dotsOptions: {
          type: qrDotStyle,
          color: solidDotColor,
          gradient: gradientDotColor
        },

        cornersSquareOptions: {
          type: qrCSStyle,
          color: solidCSColor,
          gradient: gradientCSColor
        },

        cornersDotOptions: {
          type: qrCDStyle,
          color: solidCDColor,
          gradient: gradientCDColor
        },

        backgroundOptions: {
          color: solidBGColor,
          gradient: gradientBGColor
        },

        imageOptions: {
          crossOrigin: 'anonymous',
          imageHideBGDots: imageHideBGDot,
          margin: imageMargin,
          imageSize: imageSize
        },

        qrOptions: {
          typeNumber: qrTypeNumber,
          mode: qrMode,
          errorCorrectionLevel: qrCorrectLv
        }
      });

      setDisableDownload(false || !dataUrl)

    } catch (error) {
      console.error('An error occurred:', error);
      qrRef.current.innerHTML = `<div className="text-danger">⚠️${error}.<br/>${error.includes('code length overflow') ? `Try adjusting the <b>Type Number</b> in <b>QR Options</b>` : `Please try again.`}</div>`;
      setDisableDownload(true)
    }
  }, [dataUrl, imageFile, qrWidth, qrHeight, qrMargin,
    qrDotStyle, solidDotColor, gradientDotColor,
    qrCSStyle, solidCSColor, gradientCSColor,
    qrCDStyle, solidCDColor, gradientCDColor,
    solidBGColor, gradientBGColor,
    imageHideBGDot,
    imageSize, imageMargin, qrTypeNumber, qrMode, qrCorrectLv]);


  //Dot
  useEffect(() => {
    const dataGradient = {
      type: gradientDotColorType,
      rotation: gradientDotColorRotation,
      colorStops: [{ offset: 0, color: gradientDotColorStart }, { offset: 1, color: gradientDotColorEnd }]
    }
    setGradientDotColor((radioDotColor === "single") ? null : dataGradient)

  }, [gradientDotColorType, gradientDotColorRotation, gradientDotColorStart, gradientDotColorEnd, radioDotColor]);

  //Corner square
  useEffect(() => {
    const dataGradient = {
      type: gradientCSColorType,
      rotation: gradientCSColorRotation,
      colorStops: [{ offset: 0, color: gradientCSColorStart }, { offset: 1, color: gradientCSColorEnd }]
    }
    setGradientCSColor((radioCSColor === "single") ? null : dataGradient)

  }, [gradientCSColorType, gradientCSColorRotation, gradientCSColorStart, gradientCSColorEnd, radioCSColor]);

  //Corner dot
  useEffect(() => {
    const dataGradient = {
      type: gradientCDColorType,
      rotation: gradientCDColorRotation,
      colorStops: [{ offset: 0, color: gradientCDColorStart }, { offset: 1, color: gradientCDColorEnd }]
    }
    setGradientCDColor((radioCDColor === "single") ? null : dataGradient)

  }, [gradientCDColorType, gradientCDColorRotation, gradientCDColorStart, gradientCDColorEnd, radioCDColor]);

  //Background
  useEffect(() => {
    const dataGradient = {
      type: gradientBGColorType,
      rotation: gradientBGColorRotation,
      colorStops: [{ offset: 0, color: gradientBGColorStart }, { offset: 1, color: gradientBGColorEnd }]
    }
    setGradientBGColor((radioBGColor === "single") ? null : dataGradient)

  }, [gradientBGColorType, gradientBGColorRotation, gradientBGColorStart, gradientBGColorEnd, radioBGColor]);

  //QR URL
  const onDataUrlChange = (event) => {
    event.preventDefault();
    setDataUrl(event.target.value);
  };

  //Image
  const imageFileRef = useRef(null);

  const onChangeImageFile = (event) => {
    const file = event.target.files[0];

    if (window.FileReader) {
      const reader = new FileReader();

      reader.onload = function (e) {
        const img_url = e.target.result;
        setImageFile(img_url);
      };

      reader.readAsDataURL(file);
    } else {
      // FileReader API is not supported
      // Handle this case accordingly
    }

  };

  const onCancelImageFile = (event) => {
    event.preventDefault();
    setImageFile("");
    imageFileRef.current.value = '';
  }

  const onCancelDataUrl = (event) => {
    event.preventDefault();
    setDataUrl("");
  }

  //QR Width
  const onChangeQrWidth = (event) => {
    event.preventDefault();
    setQrWidth(event.target.value);
  };

  //QR Height
  const onChangeQrHeight = (event) => {
    event.preventDefault();
    setQrHeight(event.target.value);
  };

  //QR Margin
  const onChangeQrMargin = (event) => {
    event.preventDefault();
    setQrMargin(event.target.value);
  };


  /***************
  *   DOT STYLE
  ****************/
  //QR Dot Style
  const onChangeQrDotStyle = (event) => {
    event.preventDefault();
    setQrDotStyle(event.target.value);
  };

  //Show hide dotColorOption
  const onChangeRadioDotColor = (event) => {
    // event.preventDefault();

    setRadioDotColor(event.target.value);

    if (event.target.value === "single") {
      setGradientDotColor(null)
    }

    if (event.target.value === "gradient") {
      const dataGradient = {
        type: gradientDotColorType,
        rotation: gradientDotColorRotation,
        colorStops: [{ offset: 0, color: gradientDotColorStart }, { offset: 1, color: gradientDotColorEnd }]
      }
      setGradientDotColor(dataGradient)
    }


  };

  //==== single dot color
  const onChangeSolidDotColor = (event) => {
    event.preventDefault();
    setSolidDotColor(event.target.value);
  };

  //=== gradient dot color
  const onChangeGradientDotColorType = (event) => {
    // event.preventDefault();
    setGradientDotColorType(event.target.value);
  };

  const onChangeGradientDotColorRotation = (event) => {
    event.preventDefault();
    setGradientDotColorRotation(event.target.value);
  };

  const onChangeGradientDotColorStart = (event) => {
    event.preventDefault();
    setGradientDotColorStart(event.target.value);
  };

  const onChangeGradientDotColorEnd = (event) => {
    event.preventDefault();
    setGradientDotColorEnd(event.target.value);
  };



  /***************************
  *   CORNER SQUARE STYLE
  ****************************/
  //QR Dot Style
  const onChangeQrCSStyle = (event) => {
    event.preventDefault();
    setQrCSStyle(event.target.value);
  };
  //Show hide dotColorOption
  const onChangeRadioCSColor = (event) => {
    setRadioCSColor(event.target.value);

    if (event.target.value === "single") {
      setGradientCSColor(null)
    }

    if (event.target.value === "gradient") {
      const dataGradient = {
        type: gradientCSColorType,
        rotation: gradientCSColorRotation,
        colorStops: [{ offset: 0, color: gradientCSColorStart }, { offset: 1, color: gradientCSColorEnd }]
      }
      setGradientCSColor(dataGradient)
    }
  };

  //=== single corner square color
  const onChangeSolidCSColor = (event) => {
    event.preventDefault();
    setSolidCSColor(event.target.value);
  };

  //=== gradient dot color
  const onChangeGradientCSColorType = (event) => {
    setGradientCSColorType(event.target.value);
  };

  const onChangeGradientCSColorRotation = (event) => {
    event.preventDefault();
    setGradientCSColorRotation(event.target.value);
  };

  const onChangeGradientCSColorStart = (event) => {
    event.preventDefault();
    setGradientCSColorStart(event.target.value);
  };

  const onChangeGradientCSColorEnd = (event) => {
    event.preventDefault();
    setGradientCSColorEnd(event.target.value);
  };

  /***************************
    *   CORNER DOT STYLE
    ****************************/
  //QR Dot Style
  const onChangeQrCDStyle = (event) => {
    event.preventDefault();
    setQrCDStyle(event.target.value);
  };
  //Show hide dotColorOption
  const onChangeRadioCDColor = (event) => {
    setRadioCDColor(event.target.value);

    if (event.target.value === "single") {
      setGradientCDColor(null)
    }

    if (event.target.value === "gradient") {
      const dataGradient = {
        type: gradientCDColorType,
        rotation: gradientCDColorRotation,
        colorStops: [{ offset: 0, color: gradientCDColorStart }, { offset: 1, color: gradientCDColorEnd }]
      }
      setGradientCDColor(dataGradient)
    }
  };


  //=== single corner square color
  const onChangeSolidCDColor = (event) => {
    event.preventDefault();
    setSolidCDColor(event.target.value);
  };

  //=== gradient dot color
  const onChangeGradientCDColorType = (event) => {
    setGradientCDColorType(event.target.value);
  };

  const onChangeGradientCDColorRotation = (event) => {
    event.preventDefault();
    setGradientCDColorRotation(event.target.value);
  };

  const onChangeGradientCDColorStart = (event) => {
    event.preventDefault();
    setGradientCDColorStart(event.target.value);
  };

  const onChangeGradientCDColorEnd = (event) => {
    event.preventDefault();
    setGradientCDColorEnd(event.target.value);
  };


  /***************************
    *   BACKGROUND color
    ****************************/
  //Show hide dotColorOption
  const onChangeRadioBGColor = (event) => {
    setRadioBGColor(event.target.value);

    if (event.target.value === "single") {
      setGradientBGColor(null)
    }

    if (event.target.value === "gradient") {
      const dataGradient = {
        type: gradientBGColorType,
        rotation: gradientBGColorRotation,
        colorStops: [{ offset: 0, color: gradientBGColorStart }, { offset: 1, color: gradientBGColorEnd }]
      }
      setGradientBGColor(dataGradient)
    }
  };


  //=== single corner square color
  const onChangeSolidBGColor = (event) => {
    event.preventDefault();
    setSolidBGColor(event.target.value);
  };

  //=== gradient dot color
  const onChangeGradientBGColorType = (event) => {
    setGradientBGColorType(event.target.value);
  };

  const onChangeGradientBGColorRotation = (event) => {
    event.preventDefault();
    setGradientBGColorRotation(event.target.value);
  };

  const onChangeGradientBGColorStart = (event) => {
    event.preventDefault();
    setGradientBGColorStart(event.target.value);
  };

  const onChangeGradientBGColorEnd = (event) => {
    event.preventDefault();
    setGradientBGColorEnd(event.target.value);
  };


  //image option
  //=== hide background dot
  const onChangeHideBGDot = (event) => {
    setImageHideBGDot(!imageHideBGDot);
  };

  //=== image size
  const onImageSizeChange = (event) => {
    event.preventDefault();
    setImageSize(event.target.value);
  };

  //=== image margin
  const onImageMarginChange = (event) => {
    event.preventDefault();
    setImageMargin(event.target.value);
  };

  //QR Options
  const onQrTypeNumberChange = (event) => {
    event.preventDefault();
    setqrTypeNumber(event.target.value);
  };

  const onQrModeChange = (event) => {
    event.preventDefault();
    setQrMode(event.target.value);
  };

  const onQrCorrectLvChange = (event) => {
    event.preventDefault();
    setQrCorrectLv(event.target.value);
  };

  //Extension
  const onExtensionChange = (event) => {
    setFileExt(event.target.value);
  };

  const onDownloadQr = () => {
    qrCode.download({
      name: `qr_${getCurrentDateTimeString()}`,
      extension: fileExt
    });
  };

  const getCurrentDateTimeString = () => {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    const dateTimeString = `${year}${month}${day}_${hours}${minutes}${seconds}`;
    return dateTimeString;
  }


  return (
    <div className="container">
      <div className='row'>
        <div className='col-12'>
          <div className='qr-header'>
            <h1>QR Code Generator</h1>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-12 col-md-8'>
          <div className="accordion" id="mainAcc">
            {/* accordion-item */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="panelOpenHOne">
                <button className="accordion-button hide-icon" type="button" data-bs-toggle="collapse" data-bs-target="" aria-expanded="true" aria-controls="">
                  QR Main options
                </button>
              </h2>
              <div id="panelOCOne" className="accordion-collapse collapse show" aria-labelledby="panelOpenHOne">
                <div className="accordion-body">
                  <div className="row g-3 mb-4 align-items-center">
                    <div className="col-4 col-md-2 text-end">
                      <label htmlFor="inputData" className="col-form-label">QR Data</label>
                    </div>
                    <div className="col-6 col-md-8">
                      <input value={dataUrl} onChange={onDataUrlChange} type="text" id="inputData" className="form-control" />
                    </div>
                    <div className="col-2">
                      <button type="button" name="clearQr" onClick={onCancelDataUrl} className='btn btn-secondary w-100 btn-cancel'>
                        <span className="text-white d-lg-none">
                          <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 8L8 16M8 8L16 16" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" />
                          </svg></span><span className="d-none d-lg-inline">Clear</span></button>
                    </div>
                  </div>


                  <div className="row g-3 mb-4 align-items-center">
                    <div className="col-4 col-md-2 text-end">
                      <label htmlFor="inputFile" className="col-form-label">QR Image</label>
                    </div>
                    <div className="col-6 col-md-8">
                      <input ref={imageFileRef} onChange={onChangeImageFile} type="file" id="inputFile" className="form-control" accept="image/*" />
                    </div>
                    <div className="col-2">
                      <button type="button" name="clearImage"  onClick={onCancelImageFile} className='btn btn-secondary w-100 btn-cancel'>
                        <span className="text-white d-lg-none">
                          <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 8L8 16M8 8L16 16" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" />
                          </svg></span><span className="d-none d-lg-inline">Clear</span></button>
                    </div>
                  </div>


                  <div className="row g-3 align-items-center">
                    <div className="col-4 col-md-2 text-end">
                      <label htmlFor="inputWidth" className="col-form-label">Width (px)</label>
                    </div>
                    <div className="col-8 col-md-2">
                      <input value={qrWidth} onChange={onChangeQrWidth} id="inputWidth" className="form-control" type="number" min="100" max="10000"></input>
                    </div>
                    <div className="col-4 col-md-2 text-end">
                      <label htmlFor="inputHeight" className="col-form-label">Height (px)</label>
                    </div>
                    <div className="col-8 col-md-2">
                      <input value={qrHeight} onChange={onChangeQrHeight} id="inputHeight" className="form-control" type="number" min="100" max="10000"></input>
                    </div>
                    <div className="col-4 col-md-2 text-end">
                      <label htmlFor="inputMargin" className="col-form-label">Margin (px)</label>
                    </div>
                    <div className="col-8 col-md-2">
                      <input value={qrMargin} onChange={onChangeQrMargin} id="inputMargin" className="form-control" type="number" min="0" max="500"></input>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            {/* accordion-item */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="panelOpenHTwo">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelOCTwo" aria-expanded="false" aria-controls="panelOCTwo">
                  Dots Options
                </button>
              </h2>
              <div id="panelOCTwo" className="accordion-collapse collapse" aria-labelledby="panelOpenHTwo">
                <div className="accordion-body">


                  <div className="row g-3 mb-4 align-items-center">
                    <div className="col-4 col-md-2 text-end">
                      <label htmlFor="inputDotStyle" className="col-form-label">Dots Style</label>
                    </div>
                    <div className="col-8 col-md-4">
                      <select value={qrDotStyle} onChange={onChangeQrDotStyle} id="inputDotStyle" className="form-select">
                        <option value="square">Square</option>
                        <option value="dots">Dot</option>
                        <option value="rounded">Rounded</option>
                        <option value="extra-rounded">Extra rounded</option>
                        <option value="classy">Classy</option>
                        <option value="classy-rounded">Classy rounded</option>
                      </select>
                    </div>

                    <div className="col-4 col-md-2 text-end">
                      <label className="col-form-label">Color Type</label>
                    </div>
                    <div className="col-4 col-md-2">
                      <div className='form-check'>
                        <input type="radio" value="single" onChange={onChangeRadioDotColor} checked={radioDotColor === "single"} className="form-check-input" name="radioDotColorType" id="radiosolidColor" />
                        <label className="form-check-label" htmlFor="radiosolidColor">Single</label>
                      </div>
                    </div>
                    <div className="col-4 col-md-2">
                      <div className='form-check'>
                        <input type="radio" value="gradient" onChange={onChangeRadioDotColor} checked={radioDotColor === "gradient"} className="form-check-input" name="radioDotColorType" id="radioGradientColor" />
                        <label className="form-check-label" htmlFor="radioGradientColor">Gradient</label>
                      </div>
                    </div>
                  </div>

                  {/* Type Solid color */}
                  {radioDotColor === 'single' && (
                    <SolidColorBox
                      elementName={"DotsOptions"}
                      solidColor={solidDotColor}
                      onChangeSolidColor={onChangeSolidDotColor}
                    />
                  )}

                  {/* Type Gradient color */}
                  {radioDotColor === 'gradient' && (
                    <GradientColorBox
                      elementName={"DotsOptions"}
                      gradientColorType={gradientDotColorType}
                      onChangeGradientColorType={onChangeGradientDotColorType}
                      gradientColorStart={gradientDotColorStart}
                      onChangeGradientColorStart={onChangeGradientDotColorStart}
                      gradientColorEnd={gradientDotColorEnd}
                      onChangeGradientColorEnd={onChangeGradientDotColorEnd}
                      gradientColorRotation={gradientDotColorRotation}
                      onChangeGradientColorRotation={onChangeGradientDotColorRotation}
                    />
                  )}

                </div>
              </div>
            </div>

            {/* accordion-item */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="panelOpenHThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelOCThree" aria-expanded="false" aria-controls="panelOCThree">
                  Corners Square Options
                </button>
              </h2>
              <div id="panelOCThree" className="accordion-collapse collapse" aria-labelledby="panelOpenHThree">
                <div className="accordion-body">

                  <div className="row g-3 mb-4 align-items-center">
                    <div className="col-5 col-md-2 text-end">
                      <label htmlFor="inputCSType" className="col-form-label">Corner Square</label>
                    </div>
                    <div className="col-7 col-md-4">
                      <select value={qrCSStyle} onChange={onChangeQrCSStyle} id="inputCSType" className="form-select">
                        <option value="square">Square</option>
                        <option value="dot">Dot</option>
                        <option value="extra-rounded">Extra rounded</option>
                      </select>
                    </div>
                    <div className="col-4 col-md-2 text-end">
                      <label htmlFor="inputCSColorType" className="col-form-label">Color Type</label>
                    </div>
                    <div className="col-4 col-md-2">
                      <div className='form-check'>
                        <input className="form-check-input" value="single" onChange={onChangeRadioCSColor} checked={radioCSColor === "single"} type="radio" name="radioCSColor" id="radioCSsolidColor" />
                        <label className="form-check-label" htmlFor="radioCSsolidColor">Single</label>
                      </div>
                    </div>
                    <div className="col-4 col-md-2">
                      <div className='form-check'>
                        <input className="form-check-input" value="gradient" onChange={onChangeRadioCSColor} checked={radioCSColor === "gradient"} type="radio" name="radioCSColor" id="radioCSGradientColor" />
                        <label className="form-check-label" htmlFor="radioCSGradientColor">Gradient</label>
                      </div>
                    </div>
                  </div>

                  {/* Type Solid color */}
                  {radioCSColor === 'single' && (
                    <SolidColorBox
                      elementName={"CornersSquareOptions"}
                      solidColor={solidCSColor}
                      onChangeSolidColor={onChangeSolidCSColor}
                    />
                  )}

                  {/* Type Gradient color */}
                  {radioCSColor === 'gradient' && (
                    <GradientColorBox
                      elementName={"CornersSquareOptions"}
                      gradientColorType={gradientCSColorType}
                      onChangeGradientColorType={onChangeGradientCSColorType}
                      gradientColorStart={gradientCSColorStart}
                      onChangeGradientColorStart={onChangeGradientCSColorStart}
                      gradientColorEnd={gradientCSColorEnd}
                      onChangeGradientColorEnd={onChangeGradientCSColorEnd}
                      gradientColorRotation={gradientCSColorRotation}
                      onChangeGradientColorRotation={onChangeGradientCSColorRotation}
                    />
                  )}


                </div>
              </div>
            </div>

            {/* accordion-item */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="panelOpenHFour">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelOCFour" aria-expanded="false" aria-controls="panelOCFour">
                  Corners Dot Options
                </button>
              </h2>
              <div id="panelOCFour" className="accordion-collapse collapse" aria-labelledby="panelOpenHFour">
                <div className="accordion-body">


                  <div className="row g-3 mb-4 align-items-center">
                    <div className="col-6 col-md-2 text-end">
                      <label htmlFor="inputCDType" className="col-form-label">Corner Dots Style</label>
                    </div>
                    <div className="col-6 col-md-4">
                      <select value={qrCDStyle} onChange={onChangeQrCDStyle} id="inputCDType" className="form-select">
                        <option value="square">Square</option>
                        <option value="dot">Dot</option>
                      </select>
                    </div>
                    <div className="col-4 col-md-2 text-end">
                      <label htmlFor="inputCDColorType" className="col-form-label">Color Type</label>
                    </div>
                    <div className="col-4 col-md-2">
                      <div className='form-check'>
                        <input className="form-check-input" value="single" onChange={onChangeRadioCDColor} checked={radioCDColor === "single"} type="radio" name="radioCDColor" id="radioCDSolidColor" />
                        <label className="form-check-label" htmlFor="radioCDSolidColor">Single</label>
                      </div>
                    </div>
                    <div className="col-4 col-md-2">
                      <div className='form-check'>
                        <input className="form-check-input" value="gradient" onChange={onChangeRadioCDColor} checked={radioCDColor === "gradient"} type="radio" name="radioCDColor" id="radioCDGradientColor" />
                        <label className="form-check-label" htmlFor="radioCDGradientColor">Gradient</label>
                      </div>
                    </div>
                  </div>

                  {/* Type Solid color */}
                  {radioCDColor === 'single' && (
                    <SolidColorBox
                      elementName={"CornersDotOptions"}
                      solidColor={solidCDColor}
                      onChangeSolidColor={onChangeSolidCDColor}
                    />
                  )}

                  {/* Type Gradient color */}
                  {radioCDColor === 'gradient' && (
                    <GradientColorBox
                      elementName={"CornersDotOptions"}
                      gradientColorType={gradientCDColorType}
                      onChangeGradientColorType={onChangeGradientCDColorType}
                      gradientColorStart={gradientCDColorStart}
                      onChangeGradientColorStart={onChangeGradientCDColorStart}
                      gradientColorEnd={gradientCDColorEnd}
                      onChangeGradientColorEnd={onChangeGradientCDColorEnd}
                      gradientColorRotation={gradientCDColorRotation}
                      onChangeGradientColorRotation={onChangeGradientCDColorRotation}
                    />
                  )}


                </div>
              </div>
            </div>

            {/* accordion-item */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="panelOpenHFive">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelOCFive" aria-expanded="false" aria-controls="panelOCFive">
                  Background Options
                </button>
              </h2>
              <div id="panelOCFive" className="accordion-collapse collapse" aria-labelledby="panelOpenHFive">
                <div className="accordion-body">

                  <div className="row g-3 mb-4 align-items-center">
                    <div className="col-4 col-md-2 text-end">
                      <label htmlFor="inputBGColorType" className="col-form-label">Color Type</label>
                    </div>
                    <div className="col-4 col-md-2">
                      <div className='form-check'>
                        <input className="form-check-input" value="single" onChange={onChangeRadioBGColor} checked={radioBGColor === "single"} type="radio" name="radioBGColor" id="radioBGSolidColor" />
                        <label className="form-check-label" htmlFor="radioBGSolidColor">Single</label>
                      </div>
                    </div>
                    <div className="col-4 col-md-2">
                      <div className='form-check'>
                        <input className="form-check-input" value="gradient" onChange={onChangeRadioBGColor} checked={radioBGColor === "gradient"} type="radio" name="radioBGColor" id="radioBGGradientColor" />
                        <label className="form-check-label" htmlFor="radioBGGradientColor">Gradient</label>
                      </div>
                    </div>
                  </div>

                  {/* Type Solid color */}
                  {radioBGColor === 'single' && (
                    <SolidColorBox
                      elementName={"BackgroundOptions"}
                      solidColor={solidBGColor}
                      onChangeSolidColor={onChangeSolidBGColor}
                    />
                  )}

                  {/* Type Gradient color */}
                  {radioBGColor === 'gradient' && (
                    <GradientColorBox
                      elementName={"BackgroundOptions"}
                      gradientColorType={gradientBGColorType}
                      onChangeGradientColorType={onChangeGradientBGColorType}
                      gradientColorStart={gradientBGColorStart}
                      onChangeGradientColorStart={onChangeGradientBGColorStart}
                      gradientColorEnd={gradientBGColorEnd}
                      onChangeGradientColorEnd={onChangeGradientBGColorEnd}
                      gradientColorRotation={gradientBGColorRotation}
                      onChangeGradientColorRotation={onChangeGradientBGColorRotation}
                    />
                  )}


                </div>
              </div>
            </div>

            {/* accordion-item */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="panelOpenHSix">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelOCSix" aria-expanded="false" aria-controls="panelOCSix">
                  Image Options
                </button>
              </h2>
              <div id="panelOCSix" className="accordion-collapse collapse" aria-labelledby="panelOpenHSix">
                <div className="accordion-body">
                  <div className="d-none row g-3 align-items-center">
                    <div className="col-8 col-md-4 text-end">
                      <label htmlFor="inputHideBGDot" className="col-form-label">Hide Background Dots</label>
                    </div>
                    <div className="col-4 col-md-2">
                      <input onChange={onChangeHideBGDot} checked={imageHideBGDot} type="checkbox" id="inputHideBGDot" name="inputHideBGDot" className="form-check-input" />
                    </div>
                  </div>

                  <div className="row g-3 align-items-center">
                    <div className="col-4 col-md-3 text-end">
                      <label htmlFor="inputImageSize" className="col-form-label">Image size</label>
                    </div>
                    <div className="col-8 col-md-3">
                      <input onChange={onImageSizeChange} value={imageSize} id="inputImageSize" type="number" min="0" max="1" step="0.1" className="form-control" />
                    </div>
                    <div className="col-4 col-md-2 text-end">
                      <label htmlFor="inputImageMargin" className="col-form-label">Margin (px)</label>
                    </div>
                    <div className="col-8 col-md-3">
                      <input onChange={onImageMarginChange} value={imageMargin} id="inputImageMargin" type="number" min="0" max="500" className="form-control" />
                    </div>
                  </div>

                </div>
              </div>
            </div>

            {/* accordion-item */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="panelOpenHSeven">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelOCSeven" aria-expanded="false" aria-controls="panelOCSeven">
                  QR Options
                </button>
              </h2>
              <div id="panelOCSeven" className="accordion-collapse collapse" aria-labelledby="panelOpenHSeven">
                <div className="accordion-body">


                  <div className="row g-3 mb-4 align-items-center">
                    <div className="col-5 col-md-3 text-end">
                      <label htmlFor="inputQRTypeNumber" className="col-form-label">Type Number</label>
                    </div>
                    <div className="col-7 col-md-3">
                      <input value={qrTypeNumber} onChange={onQrTypeNumberChange} id="inputQRTypeNumber" type="number" min="2" max="40" className="form-control" />
                    </div>
                    <div className="col-5 col-md-2 text-end">
                      <label htmlFor="inputQRMode" className="col-form-label">Mode</label>
                    </div>
                    <div className="col-7 col-md-3">
                      <select value={qrMode} onChange={onQrModeChange} id="inputQRMode" className="form-select">
                        <option value="Numeric">Numeric</option>
                        <option value="Alphanumeric">Alphanumeric</option>
                        <option value="Byte">Byte</option>
                        <option value="Kanji">Kanji</option>
                      </select>
                    </div>
                  </div>

                  <div className="row g-3 align-items-center">
                    <div className="col-8 col-md-4 text-end">
                      <label htmlFor="inputQRCorrectLevel" className="col-form-label">Error Correction Level</label>
                    </div>
                    <div className="col-4 col-md-2">
                      <select value={qrCorrectLv} onChange={onQrCorrectLvChange} id="inputQRCorrectLevel" className="form-select">
                        <option value="L">L</option>
                        <option value="M">M</option>
                        <option value="Q">Q</option>
                        <option value="H">H</option>
                      </select>
                    </div>
                  </div>



                </div>
              </div>
            </div>

          </div>
        </div>

        {/* PREVIEW and ACTIONS */}
        <div className='col-12 col-md-4'>
          <div className='qr-preview'>
            <div className="content" ref={qrRef}></div>
            <div className="input-group py-4 justify-content-center">
              <label for="selectType" class="input-group-text"><strong>Type:</strong></label>
              <select id="selectType" className='form-select' onChange={onExtensionChange} value={fileExt}>
                <option value="png">PNG</option>
                <option value="jpeg">JPEG</option>
                <option value="svg">SVG</option>
                {/* <option value="webp">WEBP</option> */}
              </select>
              <button onClick={onDownloadQr} type="button" className={`${disableDownload ? 'disabled' : ''} btn btn-lg btn-success`}>Download</button>

            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-12'>
          <footer className='text-center pt-5'>
            <p>&copy; Designed and developed by <a href="mailto:phong.msit@gmail.com">phong.msit</a></p>
            <p className="mt-2"><small>The <a target="_blank" rel="noreferrer" href="https://github.com/kozakdenys/qr-code-styling/tree/master">open source</a> JS library <br className="d-md-none" /> for generating styled QR codes</small></p>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default App;
