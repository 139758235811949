import React from 'react';

const SolidColorBox = ({ elementName, solidColor, onChangeSolidColor }) => {
  return (
    <div className='box'>
      <h4 className='w-100 text-center'>Single color</h4>
      <div className="row g-3 align-items-center">
        <div className="col-4 col-md-3 text-end">
          <label htmlFor={`colorPicker${elementName}`} className="col-form-label">Color</label>
        </div>
        <div className="col-4 col-md-2">
          <input
            value={solidColor}
            onChange={onChangeSolidColor}
            type="color"
            className="form-control-color"
            id={`colorPicker${elementName}`}
          />
        </div>
      </div>
    </div>
  );
};

export default SolidColorBox;