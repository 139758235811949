import React from 'react';

const GradientColorBox = ({
  elementName,
  gradientColorType, onChangeGradientColorType,
  gradientColorStart, onChangeGradientColorStart,
  gradientColorEnd, onChangeGradientColorEnd,
  gradientColorRotation, onChangeGradientColorRotation
}) => {
  return (
    <div className='box'>
      <h4 className='w-100 text-center'>Gradient color</h4>
      <div className="row g-3 mb-4 align-items-center">
        <div className="col-4 col-md-3 text-end">
          <label className="col-form-label">Type</label>
        </div>
        <div className="col-4 col-md-2">
          <div className='form-check'>
            <input onChange={onChangeGradientColorType} checked={gradientColorType === "linear"} value="linear" id={`gradientLinear${elementName}`} className="form-check-input" type="radio" />
            <label className="form-check-label" htmlFor={`gradientLinear${elementName}`}>Linear</label>
          </div>
        </div>
        <div className="col-4 col-md-2">
          <div className='form-check'>
            <input onChange={onChangeGradientColorType} checked={gradientColorType === "radial"} value="radial" id={`gradientRadial${elementName}`} className="form-check-input" type="radio" />
            <label className="form-check-label" htmlFor={`gradientRadial${elementName}`}>Radial</label>
          </div>
        </div>
      </div>

      <div className="row g-3 align-items-center">
        <div className="col-4 col-md-3 text-end">
          <label className="col-form-label">Gradient color</label>
        </div>
        <div className="col-4 col-md-2">
          <input value={gradientColorStart} onChange={onChangeGradientColorStart} type="color" className="form-control-color" />
        </div>
        <div className="col-4 col-md-2">
          <input value={gradientColorEnd} onChange={onChangeGradientColorEnd} type="color" className="form-control-color" />
        </div>
        <div className="col-4 col-md-3 text-end">
          <label htmlFor={`rotation${elementName}`} className="col-form-label">Rotation</label>
        </div>
        <div className="col-8 col-md-2">
          <input id={`rotation${elementName}`} value={gradientColorRotation} onChange={onChangeGradientColorRotation} className="form-control" type="number" min="0" max="360"></input>
        </div>
      </div>
    </div>
  );
};

export default GradientColorBox;